import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Stack,
    Divider,
    Text,
    Center
} from '@chakra-ui/react';
import { disposeCustomDialog } from "../application/action/app_action";
import { stringIsNullOrEmpty, checkBrowser } from "../common/util";
import { _DEBOUNCE_DURATION_MS } from "../common/constant";
import moment from 'moment';

/// <summary>
/// Author: Juin
/// </summary>
const CustomDialog = ({
    isVisible,
    success,
    headerTitle,
    title,
    content,
    onConfirm,
    onCancel,
    headerIcon,
    footerContent,
    confirmTxt = 'OK',
    customContent,
    hideTitle = false,
    hideFooter = false,
    cancelTxt,
    isManualHideAlert = false,
    horizontalBtn = true,
    disableHardwareBackPress = false,
    isPlainTheme = false,
    homeBanner
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();
    const [buttonClickTime, setButtonClickTime] = useState(null);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        dispatch(disposeCustomDialog());
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function canelHandler() {
        if (onCancel) {
            onCancel();
        }

        closeDialogHandler();
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const confirmHandler = () => {
        let currentTime = moment();

        if (
            buttonClickTime == null ||
            currentTime.diff(moment(buttonClickTime)) > _DEBOUNCE_DURATION_MS
        ) {
            setButtonClickTime(currentTime);

            if (onConfirm) {
                onConfirm();
            }

            if (!isManualHideAlert) {
                closeDialogHandler();
            }
        }
    };

    if (!checkBrowser()) {
        return (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    closeDialogHandler();
                }}
                isOpen={isOpen}
                isCentered
                closeOnOverlayClick={!disableHardwareBackPress}
                closeOnEsc={!disableHardwareBackPress}
                size={['xs', 'xs', 'lg']}
            >
                <AlertDialogOverlay style={{ zIndex: 999999 }} />

                {homeBanner ?
                    <AlertDialogContent bg={'transparent'} boxShadow={'none'}>
                        <AlertDialogCloseButton zIndex={99} right={6} bg={'linear-gradient(#611093,#490973,#701ca4)'} borderRadius={30} border={'2px solid #f8c763'} color={'white'} />
                        <AlertDialogBody>
                            {customContent && <div>{customContent}</div>}
                        </AlertDialogBody>
                    </AlertDialogContent>
                    :
                    <AlertDialogContent className="app-modal-content">
                        {
                            !isPlainTheme && !hideTitle &&
                            <AlertDialogHeader className="app-modal-header">
                                {
                                    headerTitle &&
                                    <Text fontSize='xl' as='b' color={'#f8c763'}>{t(headerTitle)}</Text>
                                }
                                {
                                    !hideTitle && (!stringIsNullOrEmpty(title) ||
                                        stringIsNullOrEmpty(headerTitle)) &&
                                    <Text fontSize='xl' as='b' color={'#f8c763'}>
                                        {
                                            !stringIsNullOrEmpty(title) ? t(title) :
                                                success ? t('SUCCESS') : t('OOPS')
                                        }
                                    </Text>
                                }
                            </AlertDialogHeader>
                        }
                        <Divider />
                        <AlertDialogBody className="app-modal-body">
                            {
                                !isPlainTheme &&
                                <Text fontSize='md'>
                                    {
                                        !stringIsNullOrEmpty(content) ? t(content) :
                                            success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                                    }
                                </Text>
                            }
                            {customContent && <div style={{ marginTop: '0.3rem' }}>{customContent}</div>}
                        </AlertDialogBody>
                        {
                            !hideFooter &&
                            <AlertDialogFooter className="app-modal-footer">
                                <Stack direction={horizontalBtn ? 'column' : 'row'} w='100%' gap={0}>
                                    {
                                        onCancel &&
                                        <Button
                                            w='100%'
                                            className="modal-cancel-button"
                                            ref={cancelRef}
                                            onClick={() => canelHandler()}>
                                            {cancelTxt
                                                ? t(cancelTxt)
                                                : t('CANCEL')}
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => confirmHandler()}
                                        w='100%'
                                        className="modal-ok-button"
                                        style={{ borderBottomLeftRadius: onCancel ? 0 : 11, borderBottomLeftRadius: horizontalBtn ? 11 : 0 }}>
                                        {t(confirmTxt)}
                                    </Button>
                                </Stack>
                            </AlertDialogFooter>
                        }
                    </AlertDialogContent>
                }
            </AlertDialog>
        )
    } else {
        return (
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    closeDialogHandler();
                }}
                isOpen={isOpen}
                isCentered
                closeOnOverlayClick={!disableHardwareBackPress}
                closeOnEsc={!disableHardwareBackPress}
                size={homeBanner ? 'md' : ['md', 'lg', 'xl']}
            >
                <AlertDialogOverlay style={{ zIndex: 999999 }} />
                {homeBanner ?
                    <AlertDialogContent bg={'transparent'} boxShadow={'none'}>
                        <AlertDialogCloseButton zIndex={99} right={6} bg={'linear-gradient(#611093,#490973,#701ca4)'} borderRadius={30} border={'2px solid #f8c763'} color={'white'} />
                        <AlertDialogBody>
                            {customContent && <div>{customContent}</div>}
                        </AlertDialogBody>
                    </AlertDialogContent>
                    :
                    <AlertDialogContent bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={10} border={'1px solid white'} pt={'0 !important'} paddingBlock={8}>
                        {
                            !isPlainTheme && !hideTitle &&
                            <AlertDialogHeader className="app-modal-header">
                                {
                                    headerTitle &&
                                    <Text fontSize='xl' as='b' color={'#f8c763'}>{t(headerTitle)}</Text>
                                }
                                {
                                    !hideTitle && (!stringIsNullOrEmpty(title) ||
                                        stringIsNullOrEmpty(headerTitle)) &&
                                    <Text fontSize='xl' as='b' color={'#f8c763'}>
                                        {
                                            !stringIsNullOrEmpty(title) ? t(title) :
                                                success ? t('SUCCESS') : t('OOPS')
                                        }
                                    </Text>
                                }
                            </AlertDialogHeader>
                        }
                        <Divider className="divider-horizontal" />
                        <AlertDialogBody textAlign={'center'} mb={6} mt={6}>
                            {
                                !isPlainTheme &&
                                <Text fontSize='lg' fontWeight={'bold'}>
                                    {
                                        !stringIsNullOrEmpty(content) ? t(content) :
                                            success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                                    }
                                </Text>
                            }
                            {customContent && <div style={{ marginTop: '0.3rem' }}>{customContent}</div>}
                        </AlertDialogBody>
                        {
                            !hideFooter &&
                            <Center>
                                <AlertDialogFooter>
                                    <Stack direction={horizontalBtn ? 'column' : 'row'} w='100%' spacing={horizontalBtn ? 4 : 10}>
                                        {
                                            onCancel &&
                                            <Button
                                                w={36}
                                                bg={'#4c1570'}
                                                color={'white'}
                                                borderRadius={20}
                                                ref={cancelRef}
                                                onClick={() => canelHandler()}>
                                                {cancelTxt
                                                    ? t(cancelTxt)
                                                    : t('CANCEL')}
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => confirmHandler()}
                                            w={36}
                                            backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                                            color={'white'}
                                            borderRadius={20}>
                                            {t(confirmTxt)}
                                        </Button>
                                    </Stack>
                                </AlertDialogFooter>
                            </Center>
                        }
                    </AlertDialogContent>
                }
            </AlertDialog>
        )
    }
}

export default CustomDialog;