import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomInput from './input';
import CustomDialog from './dialog';
import { checkBrowser } from '../common/util';
import { ValidationPattern, ApiKey } from '../common/constant';
import { Stack } from '@chakra-ui/react';
import { showCustomDialog } from '../application/action/app_action';
import ApiEngine, { ApplicationJsonHeaderConfig } from '../common/api_engine';
import Routes from '../common/api_routes';
import { ConfigEnum } from '../common/config';

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ isVisible, onPasswordHandler }) => {
    var _dispatch = useDispatch();
    const { handleSubmit, errors, control } = useForm();
    const { t } = useTranslation();
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        setIsDialogVisible(isVisible);
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const onPasswordSubmit = async (data) => {
        try {
            var responseJson = await ApiEngine.post(Routes._FORCE_RESET_PASSWORD, { newPassword: data.newPassword, newSecondaryPassword: data.newSecondaryPassword }, { ...ApplicationJsonHeaderConfig });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            await localStorage.setItem(ConfigEnum._REQUIRED_CHANGE_PASSWORD, 'false');
            _dispatch(showCustomDialog({
                success: true,
                content: 'PASSWORD_UPDATE_SUCESSFULLY',
                onConfirm: () => onPasswordHandler(),
                disableHardwareBackPress: true
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err,
            }));
        }
    };

    return (
        <CustomDialog
            disableHardwareBackPress={true}
            isManualHideAlert={true}
            isVisible={isDialogVisible}
            title={'CHANGE_PASSWORD'}
            content={'DUE_TO_OTP_LOGIN_YOU_REQUIRED_TO_CHANGE_PASSWORD'}
            customContent={
                <Stack mt={3} paddingInline={4}>
                    <Controller
                        control={control}
                        name="newPassword"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                inputMode='tel'
                                isPassword
                                onChange={value => onChange(value)}
                                value={value}
                                label={t('NEW_PASSWORD')}
                                background={checkBrowser() ? 'linear-gradient(#5a0089,#7c15bd) !important' : 'linear-gradient(#5a0089,#7c15bd) !important'}
                                borderRadius='15px !important'
                                border={checkBrowser() ? '2px solid transparent !important' : '2px solid #e2a570 !important'}
                                _hover={{ border: '2px solid white !important' }} transitionDuration={'normal !improtant'}
                                errors={errors}
                                patternErrorTxt={t('PASSWORD_ERROR_TXT')}
                            />
                        )}
                        rules={{ required: true, minLength: 6, maxLength: 6, pattern: ValidationPattern._DIGIT_REGEX }}
                    />
                    <Controller
                        control={control}
                        name="newSecondaryPassword"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                inputMode='tel'
                                isPassword
                                onChange={value => onChange(value)}
                                value={value}
                                label={t('NEW_SECONDARY_PASSWORD')}
                                background={checkBrowser() ? 'linear-gradient(#5a0089,#7c15bd) !important' : 'linear-gradient(#5a0089,#7c15bd) !important'}
                                borderRadius='15px !important'
                                border={checkBrowser() ? '2px solid transparent !important' : '2px solid #e2a570 !important'}
                                _hover={{ border: '2px solid white !important' }} transitionDuration={'normal !improtant'}
                                errors={errors}
                                patternErrorTxt={t('PASSWORD_ERROR_TXT')}
                            />
                        )}
                        rules={{ required: true, minLength: 6, maxLength: 6, pattern: ValidationPattern._DIGIT_REGEX }}
                    />
                </Stack>
            }
            confirmTxt={'SUBMIT'}
            onConfirm={handleSubmit(onPasswordSubmit)}
            horizontalBtn={false}
        />
    )
}